import { Form, InputNumber } from 'antd';
import { VFC } from 'react';
import { useIntl } from 'react-intl';

import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import { getRequiredRule } from '../../../i18n';

interface VehicleDimensionsFieldsProps {
    name: string;
    label: string;
}

const VehicleDimensionsFields: VFC<VehicleDimensionsFieldsProps> = ({ name, label }) => {
    const { formatMessage } = useIntl();

    return (
        <Form.Item noStyle dependencies={['type']}>
            {({ getFieldValue }) =>
                getFieldValue('type') && (
                    <>
                        <Form.Item
                            label={label}
                            {...sectionCardFormItemLayout}
                            rules={[getRequiredRule(formatMessage)]}
                            required
                        >
                            <div className="flex space-x-4">
                                <Form.Item name={[name, 'width']} noStyle>
                                    <InputNumber addonAfter="cm" placeholder="0" />
                                </Form.Item>
                                <Form.Item name={[name, 'length']} noStyle>
                                    <InputNumber addonAfter="cm" placeholder="0" />
                                </Form.Item>
                                <Form.Item name={[name, 'height']} noStyle>
                                    <InputNumber addonAfter="cm" placeholder="0" />
                                </Form.Item>
                            </div>
                        </Form.Item>
                    </>
                )
            }
        </Form.Item>
    );
};

export default VehicleDimensionsFields;
