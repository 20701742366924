import { VFC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Card, Tooltip } from 'antd';
import { Dayjs } from 'dayjs';
import { EventContentArg } from '@fullcalendar/react';

import { VehiclePlanning } from '../../../queries/api/types';
import genericMessages from '../../../i18n/genericMessages';
import vehicleMessages from '../../../i18n/vehicleMessages';
import issueMessages from '../../../i18n/issueMessages';
import CustomCalendarTimeline, { FullCalendarTimelineViews } from '../../../components/CustomCalendarTimeline';
import { taskGroupCaptionMessagesMap } from '../../../i18n/taskGroupMessages';
import planningsVehiclesEventFormatter from './planningsVehiclesEventFormatter';

export interface PlanningsVehiclesProps {
    activeDate: Dayjs;
    activeView: FullCalendarTimelineViews;
    vehicleShifts: VehiclePlanning[] | undefined;
}

const PlanningsVehicles: VFC<PlanningsVehiclesProps> = ({ activeDate, activeView, vehicleShifts }) => {
    const { formatMessage, formatNumber } = useIntl();
    const planningEvents = useMemo(() => planningsVehiclesEventFormatter(vehicleShifts!), [vehicleShifts]);

    if (!vehicleShifts) return null;
    const resourceAreaColumns = [
        {
            field: 'vehicle',
            headerContent: formatMessage(genericMessages.vehicles, { count: 0 }),
            cellContent: (arg: any) => {
                return <span className="text-base font-semibold text-blue">{arg.resource.title}</span>;
            },
        },
        {
            field: 'vehicle',
            headerContent: formatMessage(vehicleMessages.ownership),
            cellContent: (arg: any) => {
                const item = arg.resource.extendedProps as VehiclePlanning;

                return (
                    <span className="text-base font-regular text-black">
                        {item.transporter ? item.transporter.name : 'ID Logistics'}
                    </span>
                );
            },
        },
        {
            field: 'computedProperties',
            headerContent: formatMessage(vehicleMessages.mileage),
            cellContent: (arg: any) => {
                const item = arg.resource.extendedProps as VehiclePlanning;

                return (
                    <span className="text-base font-regular text-black">
                        {item.mileage ? `${formatNumber(item.mileage)} km` : '-'}
                    </span>
                );
            },
        },
    ];

    const resources = vehicleShifts.map((shift) => ({
        ...shift,
        id: shift.id,
        title: shift.reference,
    }));

    const renderEventContent = ({ event: { extendedProps } }: EventContentArg) => {
        const tooltipTitle = (
            <span className="block text-sm text-black font-bold">
                {extendedProps.type === 'issue'
                    ? formatMessage(issueMessages.statusRepairing)
                    : formatMessage(taskGroupCaptionMessagesMap.get(extendedProps.status)!)}
            </span>
        );

        return (
            <Tooltip title={tooltipTitle} overlayInnerStyle={{ borderRadius: 2, padding: '12px 16px' }} color="#ffffff">
                <div style={{ height: 16 }} />
            </Tooltip>
        );
    };

    return (
        <Card className="custom-fullcalendar border-t-0 custom-fullcalendar__planning">
            <CustomCalendarTimeline
                activeDate={activeDate}
                initialView={activeView}
                events={planningEvents}
                eventSources={vehicleShifts}
                eventContent={renderEventContent}
                nowIndicator
                resources={resources}
                resourceAreaColumns={resourceAreaColumns}
                slotDuration="24:00"
                slotLabelFormat={{
                    weekday: 'short',
                    day: 'numeric',
                    month: '2-digit',
                }}
                slotMinWidth={115}
            />
        </Card>
    );
};

export default PlanningsVehicles;
