import { useCallback, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spin, Tabs } from 'antd';

import { getRoute, RoutePathName } from '../../../routes';
import { Operator, OperatorAbsenceStatus } from '../../../queries/api/types';
import { useOperatorDetails, useOperatorExport } from '../../../queries/operators';
import operatorMessages from '../../../i18n/operatorMessages';
import genericMessages from '../../../i18n/genericMessages';
import { getFullName } from '../../../helpers';
import { errorMessage } from '../../../helpers/message';
import ListTitle from '../../../components/ListTitle';
import ApiResult from '../../../components/ApiResult';
import { Download } from '../../../components/icons';
import { ManagementOperatorsDetailsTabs } from '.';
import ManagementOperatorsDetailsInfos from './ManagementOperatorsDetailsInfos';
import ManagementOperatorsDetailsHistory from './ManagementOperatorsDetailsHistory';
import ManagementOperatorsDetailsUnavailabilities from './ManagementOperatorsDetailsUnavailabilities';
import useQueryParams from '../../../hooks/queryParams';

const ManagementOperatorsDetails: VFC = () => {
    const { operatorId, tab = ManagementOperatorsDetailsTabs.history } =
        useParams<{ operatorId: Operator['id']; tab: ManagementOperatorsDetailsTabs }>();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { data: operatorDetails, isError, error } = useOperatorDetails(operatorId, { enabled: !!operatorId });
    const pageTitle = [getFullName(operatorDetails), operatorDetails?.reference && `(${operatorDetails?.reference})`]
        .filter(Boolean)
        .join(' ') || <Spin />;
    const onChangeTab = useCallback(
        (tab) => {
            history.push(
                getRoute(RoutePathName.managementOperatorsDetails, {
                    operatorId,
                    tab: tab as ManagementOperatorsDetailsTabs,
                })
            );
        },
        [history, operatorId]
    );

    const [queryParams] = useQueryParams('ManagementOperatorsDetailsUnavailabilities');
    const sort = queryParams.get('sort') ?? undefined;
    const sortOrder = queryParams.get('sortOrder') ?? undefined;
    const status = (queryParams.getAll('status') as OperatorAbsenceStatus[]) ?? undefined;

    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const { isLoading: isExporting } = useOperatorExport(
        { operatorId, exportType: tab, sort, sortOrder, status },
        {
            enabled: isExportEnabled,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setIsExportEnabled(false);
            },
        }
    );

    return isError ? (
        <ApiResult status={error.response?.status} />
    ) : (
        <>
            <div className="flex justify-between items-center space-x-2">
                <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.managementOperators)}>
                    {pageTitle}
                </ListTitle>
                <Button
                    type="primary"
                    icon={<Download className="text-base" />}
                    onClick={() => setIsExportEnabled(true)}
                    loading={isExporting}
                >
                    <FormattedMessage {...genericMessages.export} tagName="span" />
                </Button>
            </div>
            <Tabs onChange={onChangeTab} defaultActiveKey={tab}>
                {Object.values(ManagementOperatorsDetailsTabs).map((tab) => {
                    switch (tab) {
                        case ManagementOperatorsDetailsTabs.information:
                            return (
                                <Tabs.TabPane tab={formatMessage(genericMessages.informations)} key={tab}>
                                    <ManagementOperatorsDetailsInfos />
                                </Tabs.TabPane>
                            );

                        case ManagementOperatorsDetailsTabs.history:
                            return (
                                <Tabs.TabPane tab={formatMessage(genericMessages.historyAndAnalytics)} key={tab}>
                                    <ManagementOperatorsDetailsHistory />
                                </Tabs.TabPane>
                            );

                        case ManagementOperatorsDetailsTabs.absences:
                            return (
                                <Tabs.TabPane tab={formatMessage(operatorMessages.unavailabilities)} key={tab}>
                                    <ManagementOperatorsDetailsUnavailabilities />
                                </Tabs.TabPane>
                            );

                        default:
                            return null;
                    }
                })}
            </Tabs>
        </>
    );
};

export default ManagementOperatorsDetails;
