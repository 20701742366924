import dayjs from 'dayjs';

import { IssueStatus, TaskGroupStatus, VehiclePlanning } from '../../../queries/api/types';

export interface GenericVehicleEvent {
    resourceId: string;
    status: string;
    start: string;
    end: string;
    backGroundColor?: string;
    type: 'issue' | 'taskGroup';
}

const removeTaskGroupAccordingIssue = (
    issues: VehiclePlanning['issues'],
    taskGroups: VehiclePlanning['taskGroups']
) => {
    return taskGroups.filter((taskGroup) => !issues.some((issue) => issue.date === taskGroup.date));
};

const issueEventFormatter = (
    resourceId: GenericVehicleEvent['resourceId'],
    issues: VehiclePlanning['issues']
): GenericVehicleEvent[] => {
    return issues.map((issue) => ({
        resourceId: resourceId,
        status: issue.status,
        start: dayjs(issue.date).startOf('day').toISOString(),
        end: (issue.closedAt ? dayjs(issue.closedAt).endOf('day') : dayjs(issue.date).endOf('month')).toISOString(),
        backgroundColor: '#f97315', // @orange
        type: 'issue',
    }));
};

const taskGroupEventFormatter = (
    resourceId: GenericVehicleEvent['resourceId'],
    taskGroups: VehiclePlanning['taskGroups']
): GenericVehicleEvent[] => {
    return taskGroups.map((taskGroup) => ({
        resourceId: resourceId,
        status: taskGroup.status,
        start: dayjs(taskGroup.date).startOf('day').toISOString(),
        end: dayjs(taskGroup.endDate ? taskGroup.endDate : taskGroup.date)
            .endOf('day')
            .toISOString(),
        backgroundColor: taskGroup.status === TaskGroupStatus.readyToStart ? '#5c758a' /* faded-blue */ : '#16a1b8', // @duck-blue,
        type: 'taskGroup',
    }));
};

export function planningEventsFormatter(vehiclePlannings: VehiclePlanning[]): GenericVehicleEvent[] {
    const ranges = vehiclePlannings.reduce((acc: GenericVehicleEvent[], currentPlanning) => {
        // Remove issues events with duplicates dates from data and sort the result by date in ascending order
        let currentPlanningIssues = currentPlanning.issues
            .sort((a, b) => {
                if (a.status === IssueStatus.repairing && b.status !== IssueStatus.repairing) {
                    return -1;
                }
                if (dayjs(a.closedAt).valueOf() > dayjs(b.closedAt).valueOf()) {
                    return -1;
                }
                if (dayjs(a.date).valueOf() > dayjs(b.date).valueOf()) {
                    return 1;
                }
                return 0;
            })
            .filter((value, index, self) => index === self.findIndex((t) => t.date === value.date))
            .filter((value, _, self) =>
                value.status !== IssueStatus.repairing
                    ? true
                    : !self.some(
                          (issue) => issue.status === IssueStatus.repairing && dayjs(issue.date).isBefore(value.date)
                      )
            );
        // Remove taskGroup events with duplicates dates from data and sort the result by date in ascending order
        // Remove taskGroupEvents with the same date has an issue to prioritize issue display
        let currentPlanningTaskGroups = removeTaskGroupAccordingIssue(
            currentPlanningIssues,
            currentPlanning.taskGroups.filter(
                (value, index, self) => index === self.findIndex((t) => t.date === value.date)
            )
        ).sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf());

        // Merges events with consecutive dates and same status/type
        currentPlanningIssues = currentPlanningIssues.filter((currentPlanningIssue, index, array) => {
            const previousEventGotSameStatus = array[index - 1]
                ? array[index - 1].status === currentPlanningIssue.status
                : false;

            const previousEventGotSuccessiveDate = array[index - 1]
                ? dayjs(currentPlanningIssue.date)
                      .startOf('day')
                      .diff(dayjs(array[index - 1].date).startOf('day'), 'day') === 1
                : false;

            if (previousEventGotSameStatus && previousEventGotSuccessiveDate) {
                array[index - 1].closedAt = currentPlanningIssue.closedAt;
                return false;
            } else {
                return true;
            }
        });
        currentPlanningTaskGroups = currentPlanningTaskGroups.filter((currentPlanningTaskGroup, index, array) => {
            const previousDayEventGotSameStatus = array[index - 1]
                ? array[index - 1].status === currentPlanningTaskGroup.status
                : false;

            const previousEventGotSuccessiveDate = array[index - 1]
                ? dayjs(currentPlanningTaskGroup.date)
                      .startOf('day')
                      .diff(dayjs(array[index - 1].date).startOf('day'), 'day') === 1
                : false;

            if (previousDayEventGotSameStatus && previousEventGotSuccessiveDate) {
                array[index - 1].endDate = currentPlanningTaskGroup.endDate;
                return false;
            } else {
                return true;
            }
        });

        acc.push(...issueEventFormatter(currentPlanning.id, currentPlanningIssues));
        acc.push(...taskGroupEventFormatter(currentPlanning.id, currentPlanningTaskGroups));
        return acc;
    }, []);
    return ranges;
}

export default planningEventsFormatter;
