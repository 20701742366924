import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    PlaceCreatePayload,
    PlaceIdPayload,
    PlaceListPayload,
    PlaceListResponse,
    PlaceUpdatePayload,
    remove,
    update,
    exportPlaces,
} from './api/places';
import { Place } from './api/types';

export const placesKeys = {
    all: ['places'],
    lists: () => [...placesKeys.all, 'list'],
    list: (params?: PlaceListPayload) => [...placesKeys.lists(), params],
    details: () => [...placesKeys.all, 'details'],
    detail: (id?: PlaceIdPayload) => [...placesKeys.details(), id],
    listsWithDispatcher: () => [...placesKeys.all, 'dispatcher'],
    checkDispatcher: (placeIds: PlaceIdPayload[]) => [...placesKeys.listsWithDispatcher(), placeIds],
    export: () => [...placesKeys.all, 'export'],
};

export const usePlaceList = <TData = PlaceListResponse>(
    params?: PlaceListPayload,
    options?: UseQueryOptions<PlaceListResponse, AxiosError, TData>
) => {
    return useQuery<PlaceListResponse, AxiosError, TData>(placesKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const usePlaceDetails = <TData = Place>(
    id?: PlaceIdPayload,
    options?: UseQueryOptions<Place, AxiosError, TData>
) => {
    return useQuery<Place, AxiosError, TData>(placesKeys.detail(id), async () => await details(id), options);
};

export const usePlaceCreate = (options?: UseMutationOptions<Place, AxiosError, PlaceCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Place, AxiosError, PlaceCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(placesKeys.lists());
        },
    });
};

export const usePlaceUpdate = (options?: UseMutationOptions<Place, AxiosError, PlaceUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Place, AxiosError, PlaceUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(placesKeys.detail(variables.id));
        },
    });
};

export const usePlaceRemove = (options?: UseMutationOptions<undefined, AxiosError, PlaceIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, PlaceIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(placesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(placesKeys.lists());
        },
    });
};

export const useExportPlaces = <TData = Place[]>(options?: UseQueryOptions<undefined, AxiosError, TData>) => {
    return useQuery<undefined, AxiosError, TData>(placesKeys.export(), async () => await exportPlaces(), {
        keepPreviousData: true,
        ...options,
    });
};
