import { Card, Form, FormInstance, FormProps, Switch, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { sortOrderConverter } from '../../helpers';
import { errorMessage, successMessage } from '../../helpers/message';
import useQueryParams from '../../hooks/queryParams';
import genericMessages from '../../i18n/genericMessages';
import { unitMessageMap } from '../../i18n/unitMessages';
import { ValueList, ValueListField, ValueListFieldValueType, ValueListItem } from '../../queries/api/types';
import { useValueListItemList, useValueListItemRemove, useValueListItemUpdate } from '../../queries/valueListItems';
import { useValueListDetails } from '../../queries/valueLists';
import ApiResult from '../ApiResult';
import EditableCell from '../form/EditableCell';
import EditableCellActions from '../form/EditableCellActions';

interface ValueListTableProps {
    valueListSlug?: ValueList['slug'];
    queryParamsKey: string;
    onClickEdit?: (item: ValueListItem) => void;
}

const ValueListTable: VFC<ValueListTableProps> = ({ valueListSlug, queryParamsKey, onClickEdit }) => {
    const { formatMessage } = useIntl();
    const [queryParams, setQueryParams] = useQueryParams(queryParamsKey);
    const [form] = Form.useForm();
    const [, setFormErrors] = useState<ReturnType<FormInstance['getFieldsError']>>();
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 0 : 0;
    const sort = queryParams.get('sort') || undefined;
    const sortOrder = queryParams.get('sortOrder') || undefined;
    const {
        data: valueList,
        isSuccess: valueListIsSuccess,
        isLoading: valueListIsLoading,
        isFetching: valueListIsFetching,
        isError: valueListIsError,
        error: valueListError,
    } = useValueListDetails(valueListSlug, { enabled: !!valueListSlug });
    const {
        data: valueListItem,
        isSuccess: valueListItemIsSuccess,
        isLoading: valueListItemIsLoading,
        isFetching: valueListItemIsFetching,
        isError: valueListItemIsError,
        error: valueListItemError,
    } = useValueListItemList({ valueListSlug, page, sort, sortOrder }, { enabled: !!valueListSlug });
    const isSuccess = valueListIsSuccess && valueListItemIsSuccess;
    const isLoading = valueListIsLoading || valueListItemIsLoading;
    const isFetching = valueListIsFetching || valueListItemIsFetching;
    const isError = valueListIsError || valueListItemIsError;
    const error = valueListError || valueListItemError;
    const { mutate: updateValueListItem, isLoading: isUpdating } = useValueListItemUpdate();
    const { mutate: removeValueListItem, isLoading: isRemoving } = useValueListItemRemove();
    const fields = valueList?.fields;
    const renderField = (field: ValueListField, record: any) => {
        switch (field.valueType) {
            case ValueListFieldValueType.boolean:
                return <Switch disabled checked={record} />;

            default:
                return field.unit ? (
                    <>
                        {record} <FormattedMessage {...unitMessageMap.get(field.unit)} />
                    </>
                ) : (
                    record
                );
        }
    };
    let columns: ColumnsType<ValueListItem> | undefined = fields?.map((field) => ({
        title: field.columnTitle || field.title,
        key: field.sortKey || field.title,
        sorter: field.isSortable,
        dataIndex: ['fields', field.name],
        render: (record) => renderField(field, record),
        onCell: (record) => ({
            valueType: field.valueType,
            name: ['fields', field.name],
            title: field.columnTitle || field.title,
            required: field.isRequired,
            // translation: true,
            editing: isEditing(record),
        }),
    }));
    const onTableChange: TableProps<ValueListItem>['onChange'] = (pagination, _, sorter) => {
        const sortObj = Array.isArray(sorter) ? sorter?.[0] : sorter;

        setQueryParams({
            page: (pagination.current ?? 1) - 1,
            sort: sortObj.column?.dataIndex ?? sortObj.column?.key ?? undefined,
            sortOrder: sortObj.order ? sortOrderConverter(sortObj.order) : undefined,
        });
    };
    const [editingId, setEditingId] = useState<ValueListItem['id']>();
    const isEditing = (record: ValueListItem) => record.id === editingId;
    const editItem = (record: ValueListItem) => () => {
        if (onClickEdit) {
            onClickEdit(record);
        } else {
            form.resetFields();
            form.setFieldsValue({ ...record });
            setEditingId(record.id);
        }
    };

    const cancelItem = () => {
        setEditingId(undefined);
    };

    const onFormFinish: FormProps['onFinish'] = (values) => {
        updateValueListItem(
            {
                id: editingId,
                valueListSlug,
                title: 'dummy',
                meta: {},
                ...values,
            },
            {
                onSuccess: () => {
                    successMessage({
                        content: formatMessage({
                            id: 'value_list_table.edit.success_message',
                            defaultMessage: 'Valeur modifiée avec succès',
                        }),
                    });
                    setEditingId(undefined);
                },
                onError: (error) => {
                    errorMessage({
                        content: formatMessage(genericMessages.defaultErrorWithStatus, {
                            status: error.response?.status,
                        }),
                    });
                },
            }
        );
    };

    const removeItem = (record: ValueListItem) => () => {
        removeValueListItem({ valueListSlug: valueList?.slug, id: record.id });
    };

    const onFormValuesChange = () => {
        setFormErrors(form.getFieldsError());
    };

    const onFormError = () => {
        setFormErrors(form.getFieldsError());
    };

    if (isError) {
        return <ApiResult status={error?.response?.status} />;
    }

    if (columns) {
        columns = columns.concat([
            {
                title: 'Actions',
                width: 128,
                render: (_, valueListItem) => (
                    <EditableCellActions
                        isEditing={isEditing(valueListItem)}
                        onEdit={editItem(valueListItem)}
                        onRemove={removeItem(valueListItem)}
                        onCancel={cancelItem}
                        loading={isUpdating || isRemoving}
                    />
                ),
            },
        ]);
    }

    return isSuccess && !columns ? (
        <ApiResult status={404} />
    ) : (
        <Form form={form} onFinishFailed={onFormError} onFinish={onFormFinish} onValuesChange={onFormValuesChange}>
            <Card>
                <Table<ValueListItem>
                    columns={columns}
                    className="value-list-table table-fill-card"
                    rowKey="id"
                    loading={isLoading || isFetching}
                    dataSource={valueListItem?.items}
                    onChange={onTableChange}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    pagination={{
                        total: valueListItem?.totalCount,
                        current: page + 1,
                        pageSize: valueListItem?.pageSize,
                        position: ['bottomCenter'],
                        hideOnSinglePage: true,
                    }}
                />
            </Card>
        </Form>
    );
};

export default ValueListTable;
