import { Popover, Tag, Typography } from 'antd';
import { VFC } from 'react';

import { getFullName } from '../helpers';
import { Contact } from '../queries/api/types';
import { Mail, Phone } from './icons';

interface ContactTagProps {
    contact: Contact;
}

const ContactTag: VFC<ContactTagProps> = ({ contact }) => (
    <Popover
        content={
            <>
                <Typography.Paragraph>
                    <Phone className="font-base text-faded-blue" /> {contact.phone}
                </Typography.Paragraph>
                <Typography.Paragraph>
                    <Mail className="font-base text-faded-blue" /> {contact.email}
                </Typography.Paragraph>
            </>
        }
        title={
            <>
                <Typography.Paragraph strong>{getFullName(contact)}</Typography.Paragraph>
                <Typography.Text>{contact.jobTitle}</Typography.Text>
            </>
        }
    >
        <Tag>{getFullName(contact)}</Tag>
    </Popover>
);

export default ContactTag;
