import { defineMessages } from 'react-intl';

import { IssueCriticityLevel, IssueStatus } from '../queries/api/types';

const issueMessages = defineMessages({
    statusOpened: {
        id: 'issue.status.opened',
        defaultMessage: 'Ouvert',
        description: 'Issue status',
    },
    statusRepairing: {
        id: 'issue.status.repairing',
        defaultMessage: 'En cours de réparation',
        description: 'Issue status',
    },
    statusClosed: {
        id: 'issue.status.closed',
        defaultMessage: 'Fermé',
        description: 'Issue status',
    },
    criticityLevelLow: {
        id: 'issue.criticityLevel.low',
        defaultMessage: 'Faible',
        description: 'Criticity level',
    },
    criticityLevelMedium: {
        id: 'issue.criticityLevel.medium',
        defaultMessage: 'Moyen',
        description: 'Criticity level',
    },
    criticityLevelHigh: {
        id: 'issue.criticityLevel.high',
        defaultMessage: 'Important',
        description: 'Criticity level',
    },
    criticityLevelCritical: {
        id: 'issue.criticityLevel.critical',
        defaultMessage: 'Nécessite une réparation immédiate',
        description: 'Criticity level',
    },
    taskGroupReference: {
        id: 'issue.task_group.reference',
        defaultMessage: 'Tournée n°{reference}',
    },
    closeIssue: {
        id: 'issue.close_issue',
        defaultMessage: "Fermer l'incident",
        description: 'Close issue',
    },
    declareRepair: {
        id: 'issue.declare_repair',
        defaultMessage: 'Déclarer en réparation',
        description: 'Declare repair',
    },
    updatedAt: {
        id: 'issue.updatedAt',
        defaultMessage: 'Déclaré en réparation le {updatedAt}',
    },
    closedAt: {
        id: 'issue.closed_at',
        defaultMessage: 'Fermé le {closedAt}',
    },
    userComment: {
        id: 'issue.user_comment',
        defaultMessage: 'Votre commentaire :',
    },
    otherUserComment: {
        id: 'issue.other_user_comment',
        defaultMessage: 'Commentaire de {otherUser} ajouté le {date}',
    },
    addCommentTitle: {
        id: 'issue.add_comment.title',
        defaultMessage: 'Ajouter un commentaire',
    },
    addCommentLabel: {
        id: 'issue.add_comment.label',
        defaultMessage: 'Commentaire (facultatif)',
    },
    addCommentPlaceholder: {
        id: 'issue.add_comment.placeholder',
        defaultMessage: 'Saisir un commentaire',
    },
    addCommentSuccess: {
        id: 'issue.add_comment.success',
        defaultMessage: 'Commentaire ajouté avec succès',
    },
    declareRepairModalTitle: {
        id: 'issue.declare_repair.modal.title',
        defaultMessage: 'Confirmation de mise en réparation',
    },
    declareRepairModalDesc: {
        id: 'issue.declare_repair.modal.description',
        defaultMessage: 'Êtes-vous sur de vouloir déclarer le véhicule en réparation ?',
    },
    declareRepairSuccess: {
        id: 'issue.declare_repair.success',
        defaultMessage: 'Véhicule déclaré en réparation avec succès',
    },
    closeIssueModalTitle: {
        id: 'issue.close_issue.modal.title',
        defaultMessage: 'Confirmation de fermeture',
    },
    closeIssueModalDesc: {
        id: 'issue.close_issue.modal.description',
        defaultMessage: 'Êtes-vous sur de vouloir déclarer l’incident comme fermé ?',
    },
    closeIssueSuccess: {
        id: 'issue.close_issue.success',
        defaultMessage: 'Incident fermé avec succès',
    },
    removeIssueSuccess: {
        id: 'issue.remove_issue.success',
        defaultMessage: 'Incident supprimé avec succès',
    },
});

export default issueMessages;

export const issueStatusMessageMap = new Map([
    [IssueStatus.opened, issueMessages.statusOpened],
    [IssueStatus.repairing, issueMessages.statusRepairing],
    [IssueStatus.closed, issueMessages.statusClosed],
]);

export const issueCriticityLevelMessageMap = new Map([
    [IssueCriticityLevel.low, issueMessages.criticityLevelLow],
    [IssueCriticityLevel.medium, issueMessages.criticityLevelMedium],
    [IssueCriticityLevel.high, issueMessages.criticityLevelHigh],
    [IssueCriticityLevel.critical, issueMessages.criticityLevelCritical],
]);
