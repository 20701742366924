import { useEffect } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Form, FormProps, ModalProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useIntl } from 'react-intl';

import { Operator, ValueListItem, ValueListSlug } from '../../queries/api/types';
import FormField from '../form/FormField';
import { useOperatorAbsenceCreate } from '../../queries/operatorAbsences';
import { errorMessage } from '../../helpers/message';
import genericMessages from '../../i18n/genericMessages';
import formMessages from '../../i18n/formMessages';
import CustomModal from '../CustomModal';

interface OperatorAbsenceAddModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    operatorId: Operator['id'];
}

interface FormData {
    dateRange: [Dayjs, Dayjs];
    duration: number; // fullDay: 1; halfDay: 0.5;
    slot: ValueListItem['id'];
    type: ValueListItem['id'];
}

const OperatorAbsenceAddModal = NiceModal.create<OperatorAbsenceAddModalProps>(({ operatorId }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm<FormData>();
    const modal = useModal();
    const { mutate: createAbsence, isLoading } = useOperatorAbsenceCreate();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish: FormProps<FormData>['onFinish'] = (values) => {
        const payload = {
            duration: 1,
            startDate: values.dateRange[0].format('YYYY-MM-DD'),
            endDate: values.dateRange[1].format('YYYY-MM-DD'),
            slot: values.slot,
            type: values.type,
            operator: operatorId,
        };

        createAbsence(payload, {
            onSuccess: () => {
                modal.hide();
            },
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, {
                        status: error.response?.status,
                    }),
                });
            },
        });
    };

    return (
        <CustomModal
            {...antdModal(modal)}
            title={formatMessage({
                id: 'operator.absence.add_modal.title',
                defaultMessage: 'Saisir une indisponibilité',
                description: 'Modal title',
            })}
            footer={null}
        >
            <Form form={form} onFinish={onFinish} initialValues={{ dateRange: [dayjs(), dayjs()], duration: 1 }}>
                <FormField
                    required
                    name="dateRange"
                    label={formatMessage(genericMessages.period)}
                    fieldComponentProps={{ style: { width: '100%' } }}
                    type="DateRangePicker"
                    formItemProps={{
                        labelCol: { span: 8, style: { marginRight: 16 } },
                        wrapperCol: { span: 16 },
                    }}
                />
                <FormField
                    name="type"
                    label={formatMessage({
                        id: 'operator.absence.add_modal.type.label',
                        defaultMessage: 'Motif d’absence',
                        description: 'Input label',
                    })}
                    fieldComponentProps={{ valueListSlug: ValueListSlug.operatorAbsenceReasons }}
                    type="ValueListItemSelect"
                    formItemProps={{
                        labelCol: { span: 8, style: { marginRight: 16 } },
                        wrapperCol: { span: 16 },
                    }}
                    required
                />
                <div className="flex justify-end gap-4">
                    <Button
                        type="primary"
                        onClick={() => {
                            modal.hide();
                        }}
                        disabled={isLoading}
                        ghost
                    >
                        {formatMessage(formMessages.cancel)}
                    </Button>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        {formatMessage(formMessages.submit)}
                    </Button>
                </div>
            </Form>
        </CustomModal>
    );
});

export default OperatorAbsenceAddModal;
