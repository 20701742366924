import { Dayjs } from 'dayjs';

import api from './apiClient';
import { downloadResponseAsBlob, SearchPaginationQuery } from '.';
import {
    Operator,
    ListResponse,
    ValueListItem,
    Place,
    OperatorShift,
    TaskGroup,
    OperatorAbsenceByType,
    OperatorAbsence,
    OperatorCountByGroup,
    OperatorAvailability,
    OperatorCountByTransporter,
    TaskGroupStatus,
    OperatorAbsenceStatus,
} from './types';
import { ManagementOperatorsDetailsTabs } from '../../pages/management/operators';

// Controller Interface
export type OperatorCreatePayload = Partial<
    Omit<Operator, 'attachedTo' | 'contractType' | 'groups' | 'profile' | 'contractStartedAt' | 'preferedSlots'> & {
        attachedTo?: ValueListItem['id'];
        contractStartedAt: Dayjs;
        contractType?: ValueListItem['id'];
        groups?: Array<ValueListItem['id']>;
        profile?: ValueListItem['id'];
        preferedSlots?: Array<ValueListItem['id']>;
    }
>;

export type OperatorUpdatePayload = Partial<
    Omit<
        Operator,
        | 'firstName'
        | 'lastName'
        | 'attachedTo'
        | 'contractType'
        | 'groups'
        | 'profile'
        | 'contractStartedAt'
        | 'preferedSlots'
    > & {
        firstName?: string;
        lastName?: string;
        attachedTo?: ValueListItem['id'];
        contractStartedAt?: Dayjs;
        contractType?: ValueListItem['id'];
        groups?: Array<ValueListItem['id']>;
        profile?: ValueListItem['id'];
        preferedSlots?: Array<ValueListItem['id']>;
    }
>;

export type OperatorIdPayload = Operator['id'];
export type ExportOperatorPayload = {
    operatorId: Operator['id'];
    exportType: ManagementOperatorsDetailsTabs;
    sort?: string;
    sortOrder?: string;
    status?: OperatorAbsenceStatus[];
};

export type OperatorListPayload = SearchPaginationQuery & {
    isActive?: boolean;
    profiles?: Array<ValueListItem['id']>;
    idlEntities?: Array<ValueListItem['id']>;
    placeIds?: Array<Place['id']>;
    contractTypes?: Array<ValueListItem['id']>;
    preferedSlots?: Array<ValueListItem['id']>;
    groupIds?: Array<ValueListItem['id']>;
    'contractStartedAt.fromDate'?: string;
    'contractStartedAt.toDate'?: string;
};
export type OperatorListResponse = ListResponse<Operator>;

export type OperatorListShiftsPayload = SearchPaginationQuery & {
    id: Operator['id'];
    wholeDay?: boolean;
};

export type OperatorListShiftsResponse = ListResponse<OperatorShift>;

export type OperatorListTaskGroupsPayload = SearchPaginationQuery & {
    id: Operator['id'];
    status?: TaskGroupStatus;
};

export type OperatorListTaskGroupsResponse = ListResponse<TaskGroup>;

export type OperatorListAbsencesByTypePayload = SearchPaginationQuery & {
    id: Operator['id'];
};

export type OperatorListAbsencesByTypeResponse = ListResponse<OperatorAbsenceByType>;

export type OperatorListAbsencesPayload = SearchPaginationQuery & {
    id: Operator['id'];
    fromDate?: string;
    toDate?: string;
    status?: string[];
};

export type OperatorPlanningAvailabilitiesPayload = SearchPaginationQuery & {
    fromDate: string;
    toDate: string;
    places?: Array<Place['id']>;
};

export type OperatorPlanningAvailabilitiesResponse = OperatorAvailability[];

export type OperatorListAbsencesResponse = ListResponse<OperatorAbsence>;

export type OperatorCountByGroupResponse = OperatorCountByGroup[];

export type OperatorCountByTransporterPayload = SearchPaginationQuery;
export type OperatorCountByTransporterResponse = ListResponse<OperatorCountByTransporter>;

// Routes
export const list = async (payload?: OperatorListPayload) => {
    return await api.get<OperatorListResponse>(`/operators`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: OperatorCreatePayload) => {
    return await api.post(`/operators`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: OperatorUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Operator>(`/operators/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: OperatorIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Operator>(`/operators/${id}`).then((response) => response?.data);
};

export const remove = async (id?: OperatorIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/operators/${id}`).then((response) => response?.data);
};

export const listShifts = async ({ id, ...payload }: OperatorListShiftsPayload) => {
    return await api
        .get<OperatorListShiftsResponse>(`/operators/${id}/shifts`, { params: payload })
        .then((response) => response?.data);
};

export const listTaskGroups = async ({ id, ...payload }: OperatorListTaskGroupsPayload) => {
    return await api
        .get<OperatorListTaskGroupsResponse>(`/operators/${id}/taskGroups`, { params: payload })
        .then((response) => response?.data);
};

export const listAbsences = async ({ id, ...payload }: OperatorListAbsencesPayload) => {
    return await api
        .get<OperatorListAbsencesResponse>(`/operators/${id}/absences`, { params: payload })
        .then((response) => response?.data);
};

export const listAbsencesByType = async ({ id, ...payload }: OperatorListAbsencesByTypePayload) => {
    return await api
        .get<OperatorListAbsencesByTypeResponse>(`/operators/${id}/absencesByType`, { params: payload })
        .then((response) => response?.data);
};

export const planningAvailabilities = async (payload: OperatorPlanningAvailabilitiesPayload) => {
    return await api
        .get<OperatorPlanningAvailabilitiesResponse>(`/planning/operators/availabilities`, { params: payload })
        .then((response) => response?.data);
};

export const exportOperator = async ({ operatorId, exportType, sort, sortOrder, status }: ExportOperatorPayload) => {
    return await api
        .get<Blob>(`/operators/${operatorId}/${exportType}/export`, {
            responseType: 'blob',
            params: {
                sort,
                sortOrder,
                status,
            },
        })
        .then((response) => {
            downloadResponseAsBlob(response);

            return undefined;
        });
};

export const exportOperators = async () => {
    return await api
        .get<Blob>(`/exportAll/operators`, {
            responseType: 'blob',
        })
        .then((response) => {
            downloadResponseAsBlob(response);
            return undefined;
        });
};

export const generateNewPassword = async (payload: OperatorIdPayload) => {
    return await api.put<Operator>(`/operators/${payload}/generatePassword`).then((response) => response?.data);
};
