import { FC, FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import './assets/styles/App.less';

import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import CookieBanner from './components/CookieBanner';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import { getRawRoute, RoutePathName } from './routes';
import ForgottenPassword from './pages/forgottenPassword';
import ResetPassword from './pages/resetPassword';
import { useLogs } from './context/LogsContext';
import PermissionRoute, { PermissionRouteProps } from './components/PermissionRoute';
import { Permission } from './queries/api/types';
import { VehiclesList, VehiclesValueList, VehiclesDetails } from './pages/settings/vehicles';
import CustomersList from './pages/settings/customers';
import CustomerDetails from './pages/settings/customers/CustomerDetails';
import { OperatorsList, OperatorsValueList, OperatorsProfileForm, OperatorDetails } from './pages/settings/operators';
import { TransporterCreate, TransportersList, TransportersValueList } from './pages/settings/transporters';
import { PlacesList, PlacesValueList } from './pages/settings/places';
import PlacesDetails from './pages/settings/places/PlacesDetails';
import Applications from './pages/superAdmin/applications';
import ValueList from './pages/superAdmin/valueList';
import TransporterDetails from './pages/settings/transporters/TransporterDetails';
import CustomerCreate from './pages/settings/customers/CustomerCreate';
import { ManagementVehiclesDetails, ManagementVehiclesList } from './pages/management/vehicles';
import { ManagementOperatorsDetails, ManagementOperatorsList } from './pages/management/operators';
import { ManagementForecastsList } from './pages/management/forecasts';
import { ManagementTaskGroups } from './pages/management/taskGroups';
import { ManagementPlannings } from './pages/management/plannings';
import {
    PreBillingCustomerDetails,
    PreBillingCustomersList,
    PreBillingTransporterDetails,
    PreBillingTransportersList,
    TaskGroupServicesList,
} from './pages/preBilling';
import OrganizationsList from './pages/superAdmin/organizations';
import RoleList from './pages/superAdmin/roles';
// import JobList from './pages/superAdmin/jobs';
import { UsersList as SuperAdminUsersList } from './pages/superAdmin/users';
import { UserDetails, UsersList } from './pages/administration/users';

const routes: Array<PermissionRouteProps & { Children: FunctionComponent }> = [
    {
        path: getRawRoute(RoutePathName.home),
        Children: Home,
        exact: true,
    },
    {
        path: [getRawRoute(RoutePathName.customerCreate)],
        permission: Permission.customerConfiguration,
        Children: CustomerCreate,
        exact: true,
    },
    {
        path: [getRawRoute(RoutePathName.customers), getRawRoute(RoutePathName.customersDisabled)],
        permission: Permission.customerConfiguration,
        Children: CustomersList,
        exact: true,
    },
    {
        path: [getRawRoute(RoutePathName.customerDetails)],
        permission: Permission.customerConfiguration,
        Children: CustomerDetails,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.vehicleDetails),
        permission: Permission.vehicleConfiguration,
        Children: VehiclesDetails,
        exact: true,
    },
    {
        path: [getRawRoute(RoutePathName.vehicles), getRawRoute(RoutePathName.vehiclesDisabled)],
        permission: Permission.vehicleConfiguration,
        Children: VehiclesList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.vehiclesValueLists),
        permission: Permission.valueLists,
        Children: VehiclesValueList,
    },
    {
        path: [getRawRoute(RoutePathName.operators), getRawRoute(RoutePathName.operatorsDisabled)],
        permission: Permission.operatorConfiguration,
        Children: OperatorsList,
        exact: true,
    },
    {
        path: [getRawRoute(RoutePathName.operatorDetails)],
        permission: Permission.operatorConfiguration,
        Children: OperatorDetails,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.operatorsValueLists),
        permission: Permission.valueLists,
        Children: OperatorsValueList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.operatorsProfileForm),
        permission: Permission.operatorConfiguration,
        Children: OperatorsProfileForm,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.transporterDetails),
        permission: Permission.transporters,
        Children: TransporterDetails,
        exact: true,
    },
    {
        path: [getRawRoute(RoutePathName.transporters), getRawRoute(RoutePathName.transportersDisabled)],
        permission: Permission.transporterConfiguration,
        Children: TransportersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.transportersValueLists),
        permission: Permission.valueLists,
        Children: TransportersValueList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.transporterCreate),
        permission: Permission.transporterConfiguration,
        Children: TransporterCreate,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.placeDetails),
        permission: Permission.placeConfiguration,
        Children: PlacesDetails,
        exact: true,
    },
    {
        path: [getRawRoute(RoutePathName.places), getRawRoute(RoutePathName.placesDisabled)],
        permission: Permission.placeConfiguration,
        Children: PlacesList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.placesValueLists),
        permission: Permission.valueLists,
        Children: PlacesValueList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.superAdminApplications),
        permission: Permission.superAdmin,
        Children: Applications,
    },
    {
        path: getRawRoute(RoutePathName.superAdminOrganizations),
        permission: Permission.superAdmin,
        Children: OrganizationsList,
    },
    {
        path: getRawRoute(RoutePathName.superAdminValueList),
        permission: Permission.superAdmin,
        Children: ValueList,
    },
    {
        path: getRawRoute(RoutePathName.superAdminRoleList),
        permission: Permission.superAdmin,
        Children: RoleList,
    },
    // {
    //     path: getRawRoute(RoutePathName.superAdminJobList),
    //     permission: Permission.superAdmin,
    //     Children: JobList,
    // },
    {
        path: getRawRoute(RoutePathName.superAdminUserList),
        permission: Permission.superAdmin,
        Children: SuperAdminUsersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.managementVehicles),
        permission: Permission.vehicles,
        Children: ManagementVehiclesList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.managementVehiclesDetails),
        permission: Permission.vehicles,
        Children: ManagementVehiclesDetails,
    },
    {
        path: getRawRoute(RoutePathName.managementOperators),
        permission: Permission.operators,
        Children: ManagementOperatorsList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.managementOperatorsDetails),
        permission: Permission.operators,
        Children: ManagementOperatorsDetails,
    },
    {
        path: getRawRoute(RoutePathName.managementForecasts),
        permission: Permission.customerForecasts,
        Children: ManagementForecastsList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.managementTaskGroups),
        permission: Permission.vehicles,
        Children: ManagementTaskGroups,
    },
    {
        path: getRawRoute(RoutePathName.managementPlannings),
        permission: Permission.operators,
        Children: ManagementPlannings,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.managementPlannings),
        permission: Permission.operators,
        Children: ManagementPlannings,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.preBillingCustomersDetails),
        permission: Permission.customerPrebilling,
        Children: PreBillingCustomerDetails,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.preBillingCustomersTaskGroupServices),
        permission: Permission.customerPrebilling,
        Children: TaskGroupServicesList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.preBillingCustomersList),
        permission: Permission.customerPrebilling,
        Children: PreBillingCustomersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.preBillingTransportersDetails),
        permission: Permission.transporterPrebilling,
        Children: PreBillingTransporterDetails,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.preBillingTransportersTaskGroupServices),
        permission: Permission.transporterPrebilling,
        Children: TaskGroupServicesList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.preBillingTransportersList),
        permission: Permission.transporterPrebilling,
        Children: PreBillingTransportersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.usersList),
        permission: Permission.admin,
        Children: UsersList,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.usersDetails),
        permission: Permission.admin,
        Children: UserDetails,
        exact: true,
    },
];

const App: FC = () => {
    const { sendLogs } = useLogs();

    return (
        <Switch>
            <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
            <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
            <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
            <ProtectedRoute>
                <CookieBanner />
                <MainLayout>
                    <ErrorBoundary sendLogs={sendLogs} showDetails>
                        <Switch>
                            {routes.map(({ Children, ...props }) => (
                                <PermissionRoute
                                    key={Array.isArray(props.path) ? props.path.join('') : props.path}
                                    {...props}
                                >
                                    <Children />
                                </PermissionRoute>
                            ))}

                            <Route path="*">
                                <ErrorPage />
                            </Route>
                        </Switch>
                    </ErrorBoundary>
                </MainLayout>
            </ProtectedRoute>
        </Switch>
    );
};

export default App;
