import { useEffect, useMemo, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { Badge, Card, ModalProps, Tag } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import { Customer, CustomerForecast, GenericStatus } from '../../../queries/api/types';
import { useCustomerForecastsList, useCustomerForecastsUpdate, useCustomersList } from '../../../queries/customers';
import { usePublicHolidaysList } from '../../../queries/publicHolidays';
import { classNames, formatForecastDate, isBeforeToday, isToday } from '../../../helpers';
import { formatDateOverlap } from '../../../helpers/date';
import { successMessage } from '../../../helpers/message';
import genericMessages from '../../../i18n/genericMessages';
import customerMessages from '../../../i18n/customerMessages';
import CustomCalendarEvent from '../../../components/CustomCalendarEvent';
import CustomerSelect from '../../../components/selects/CustomerSelect';
import ModalOverlap from '../../../components/ModalOverlap';
import ListTitle from '../../../components/ListTitle';
import Seo from '../../../components/Seo';
import ForecastsListFormModal from './ForecastsListFormModal';
import ForecastsManagementHeader from './ForecastsManagementHeader';

export interface ForecastListForm extends Omit<CustomerForecast, 'id'> {}

interface OpenModalProps {
    customer: Customer;
    date: string;
    formInitialValues: ForecastListForm;
}

const ManagementForecastsList: VFC = () => {
    const { formatMessage } = useIntl();
    const pageTitle = formatMessage({
        id: 'menu.management.forecasts',
        defaultMessage: 'Suivi du Forecast',
        description: 'menu item',
    });

    const [activeDate, setActiveDate] = useState<Dayjs>(dayjs().startOf('week'));
    const activeWeek = Array.from({ length: 7 }).map((_, index) => activeDate.add(index, 'day'));

    const maxCustomersPerPage = 200;
    const [customers, setCustomers] = useState<Array<Customer['id']>>([]);
    const { data: forecastsList } = useCustomerForecastsList({
        fromDate: formatForecastDate(activeDate.startOf('week')),
        toDate: formatForecastDate(activeDate.endOf('week')),
        pageSize: maxCustomersPerPage * 7, // 7 for each day in a week
    });
    const { mutate: updateCustomerForecast } = useCustomerForecastsUpdate();
    const { data: customersList } = useCustomersList({ pageSize: maxCustomersPerPage });

    const customerIds = customersList?.items.map((customer) => customer.id) || [];
    const { data: publicHolidays } = usePublicHolidaysList(
        { customerIds, year: activeDate.day(7).year().toString() },
        { enabled: customerIds.length > 0 }
    );

    useEffect(() => {
        const newCustomers = customersList?.items.map((customer) => customer.id) || [];
        setCustomers(newCustomers);
    }, [customersList?.items]);

    const forecasts = useMemo(
        () =>
            [...new Set(customers)].map((customerId) => {
                const findCustomer = customersList?.items.find((customer) => customer.id === customerId);

                const filteredForecasts = forecastsList?.items?.filter(
                    (forecast) => forecast.customer?.id === customerId
                );

                const filledWeek = activeWeek.map((weekDay) => {
                    const forecastPerDay = filteredForecasts?.find((forecast) =>
                        dayjs(forecast.date).isSame(weekDay, 'day')
                    );

                    return {
                        forecast: forecastPerDay,
                        weekDay,
                    };
                });

                return {
                    customer: findCustomer,
                    forecastsWeek: filledWeek,
                };
            }),
        [activeWeek, customers, customersList?.items, forecastsList?.items]
    );

    const filterCustomers: any = (customers: Array<Customer['id']>) => {
        if (!customersList?.items) return;

        const newCustomers = customers.length > 0 ? customers : customersList?.items.map((customer) => customer.id);
        setCustomers(newCustomers);
    };

    const onSubmit = (values: ForecastListForm) => {
        const payload = {
            ...values,
            customer: values.customer.id,
            date: formatForecastDate(dayjs(values.date)),
        };

        updateCustomerForecast(payload, {
            onSuccess: () => {
                successMessage({
                    content: formatMessage(customerMessages.createVolumeSuccessMessage),
                });

                closeModal();
            },
        });
    };

    const handleForecastClick = (forecast?: CustomerForecast, event?: React.KeyboardEvent) => {
        if (!forecast) return;

        if (isBeforeToday(dayjs(forecast.date).toDate())) return;

        const modalProps = {
            customer: forecast.customer,
            date: forecast.date,
            formInitialValues: {
                ...forecast,
                ...(forecast.tolerance && {
                    toleranceSign: forecast.tolerance >= 0 ? '+' : '-',
                }),
            },
        };

        if (!event) {
            openModal(modalProps);
        }

        if (event && (event.code === 'Space' || event.code === 'Enter')) {
            openModal(modalProps);
        }
    };

    const openModal = ({ customer, date, formInitialValues }: OpenModalProps) => {
        const modalProps: ModalProps = {
            centered: true,
            children: (
                <ForecastsListFormModal
                    customer={customer}
                    date={date}
                    formInitialValues={formInitialValues}
                    handleCloseModal={closeModal}
                    handleSubmit={(values) => onSubmit({ ...formInitialValues, ...values })}
                />
            ),
            footer: false,
            title: (
                <span className="text-base text-lg font-bold">
                    {formatMessage(customerMessages.forecastListModalTitle)}
                </span>
            ),
            width: 464,
        };

        NiceModal.show(ModalOverlap, { ...modalProps });

        return false;
    };

    const closeModal = async () => await NiceModal.hide(ModalOverlap);

    return (
        <>
            <Seo title={pageTitle} />

            <div className="flex justify-between items-center mb-6">
                <ListTitle className="mb-0 uppercase">{pageTitle}</ListTitle>
            </div>

            <div
                style={{
                    backgroundColor: '#eceff3',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                }}
            >
                <ForecastsManagementHeader
                    activeDate={activeDate}
                    activeWeek={activeWeek}
                    additionalControls={
                        <div className="mr-8">
                            <CustomerSelect
                                mode="multiple"
                                maxTagCount="responsive"
                                onChange={filterCustomers}
                                size="small"
                                style={{ width: 360 }}
                            />
                        </div>
                    }
                    setActiveDate={setActiveDate}
                    title={formatDateOverlap(activeDate)}
                />
            </div>

            <Card className="border-t-0" bodyStyle={{ paddingTop: 0 }}>
                <ul className="flex flex-col gap-6 list-style-none p-0">
                    {forecasts?.map((forecast, forecastIndex) => {
                        const tag =
                            forecast.customer?.forecastStatus === GenericStatus.success
                                ? { color: 'green', text: 'Budget prév.ok' }
                                : forecast.customer?.forecastStatus === GenericStatus.warning
                                ? { color: 'orange', text: 'Budget prév.< 6 jours' }
                                : { color: 'red', text: 'Budget prév.manquant' };
                        return (
                            forecast.customer && (
                                <li key={forecastIndex}>
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="block text-base text-black font-semibold uppercase mb-0">
                                            {forecast.customer.name}
                                        </span>
                                        <Tag
                                            className="uppercase mr-0 !py-1 !flex items-center font-bold"
                                            color={tag.color}
                                            icon={<Badge color={tag.color} />}
                                        >
                                            {tag.text}
                                        </Tag>
                                    </div>
                                    <ul className="grid grid-cols-7 list-style-none p-0">
                                        {forecast.forecastsWeek.map((week, index) => {
                                            if (!forecast.customer) return null;

                                            const renderForecastEvent = dayjs(week.forecast?.date).isSame(
                                                activeWeek[index],
                                                'date'
                                            );

                                            const findPublicHolidays = publicHolidays?.find((holiday) => {
                                                const customerId = Object.keys(holiday);
                                                return customerId[0] === forecast.customer?.id;
                                            });

                                            const isPublicHoliday = findPublicHolidays
                                                ? findPublicHolidays[forecast.customer.id].includes(
                                                      activeWeek[index].format('YYYY-MM-DD')
                                                  )
                                                : false;

                                            return (
                                                <li key={index} style={{ height: 85 }}>
                                                    {isPublicHoliday && (
                                                        <div
                                                            className={classNames(
                                                                'h-full p-1 border border-l-0',
                                                                index === 0 && 'border-l'
                                                            )}
                                                        >
                                                            <div className="flex justify-center items-center fc-event-content bg-grey-light-blue">
                                                                <p className="text-xs text-grey-7 font-semibold mb-0">
                                                                    {formatMessage(genericMessages.publicHoliday)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {!isPublicHoliday && !week.forecast && (
                                                        <div
                                                            className={classNames(
                                                                'h-full p-1 border border-l-0',
                                                                index === 0 && 'border-l'
                                                            )}
                                                        >
                                                            <div className="flex justify-center items-center fc-event-content fc-event-bg-red">
                                                                <p className="text-xs text-white font-bold mb-0">
                                                                    {formatMessage(customerMessages.forecastEmpty)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {!isPublicHoliday && week.forecast && renderForecastEvent && (
                                                        <div
                                                            className={classNames(
                                                                'h-full p-1 border border-l-0',
                                                                index === 0 && 'border-l',
                                                                isBeforeToday(dayjs(week.forecast.date).toDate()) &&
                                                                    'bg-grey-light-blue',
                                                                isToday(dayjs(week.forecast.date).toDate()) &&
                                                                    'bg-duck-blue-transparent'
                                                            )}
                                                        >
                                                            <div
                                                                onClick={() => handleForecastClick(week.forecast)}
                                                                onKeyDown={(e) => handleForecastClick(week.forecast, e)}
                                                                role="button"
                                                                tabIndex={0}
                                                            >
                                                                <CustomCalendarEvent {...week.forecast} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            )
                        );
                    })}
                </ul>
            </Card>
        </>
    );
};

export default ManagementForecastsList;
