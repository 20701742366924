import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Customer, CustomerForecast, ListResponse } from './types';

// Controller Interface
export interface CustomerForecastCreatePayload {
    customer: Customer['id'];
    date: string;
    capacity: number;
    tolerance?: number;
    actualCount?: number;
}

export interface CustomerForecastDetailsPayload {
    customer: Customer['id'];
    fromDate: string;
    toDate?: string;
}

export interface CustomerForecastUpdatePayload extends Omit<CustomerForecast, 'id' | 'customer'> {
    customer: Customer['id'];
}

export interface CustomerForecastRemovePayload
    extends Omit<CustomerForecast, 'id' | 'customer' | 'capacity' | 'tolerance'> {
    customer: Customer['id'];
    date: string;
}

export type CustomerForecastIdPayload = Customer['id'];

export type CustomerForecastListPayload = SearchPaginationQuery & {
    fromDate: string;
    toDate?: string;
};

export type CustomerForecastListResponse = ListResponse<CustomerForecast>;

// Routes
export const list = async (payload?: CustomerForecastListPayload) => {
    return await api
        .get<CustomerForecastListResponse>(`/customerForecasts`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: CustomerForecastCreatePayload) => {
    const { customer: customerId, date } = payload;

    if (!customerId) {
        throw new Error('missing customerId');
    }

    if (!date) {
        throw new Error('missing date');
    }

    return await api
        .post(`/customers/${customerId}/customerForecasts/${date}`, payload)
        .then((response) => response?.data);
};

export const update = async (payload: CustomerForecastUpdatePayload) => {
    const { customer: customerId, date } = payload;

    if (!customerId) {
        throw new Error('missing customerId');
    }

    if (!date) {
        throw new Error('missing date');
    }

    return await api
        .put<CustomerForecast>(`/customers/${customerId}/customerForecasts/${date}`, payload)
        .then((response) => response?.data);
};

export const details = async (payload: CustomerForecastDetailsPayload) => {
    const { customer } = payload;

    if (!customer) {
        throw new Error('missing customer');
    }
    return await api
        .get<CustomerForecast>(`/customers/${customer}/customerForecasts`, { params: payload })
        .then((response) => response?.data);
};

export const remove = async (payload: CustomerForecastRemovePayload) => {
    const { customer, date } = payload;

    if (!customer) {
        throw new Error('missing customer');
    }
    return await api
        .delete<undefined>(`/customers/${customer}/customerForecasts/${date}`)
        .then((response) => response?.data);
};
