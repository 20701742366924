import api from './apiClient';
import { downloadResponseAsBlob, SearchPaginationQuery } from '.';
import { Place, ListResponse, ValueListItem, PlaceType, Customer, DockType } from './types';

// Controller Interface
export interface PlaceCreatePayload {
    name: string;
}

export interface PlaceUpdatePayload
    extends Omit<Place, 'id' | 'docks' | 'dockTypes' | 'workingHolidays' | 'customers' | 'secondaryPlaces'> {
    id?: Place['id'];
    docks?: Array<{
        name: string;
        allowedVehicleTypes: Array<ValueListItem['id']>;
    }>;
    dockTypes?: DockType[];
    workingHolidays?: Array<ValueListItem['id']>;
    customers?: Array<Customer['id']>;
    secondaryPlaces?: Array<Place['id']>;
}

export type PlaceIdPayload = Place['id'];

export type PlaceListPayload = SearchPaginationQuery & {
    isActive?: boolean;
    types?: PlaceType[];
    groupIds?: Array<ValueListItem['id']>;
    activityIds?: Array<ValueListItem['id']>;
    customerIds?: Array<Customer['id']>;
    secondaryPlaceIds?: Array<Place['id']>;
};
export type PlaceListResponse = ListResponse<Place>;

// Routes
export const list = async (payload?: PlaceListPayload) => {
    return await api.get<PlaceListResponse>(`/places`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: PlaceCreatePayload) => {
    return await api.post(`/places`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: PlaceUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Place>(`/places/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: PlaceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Place>(`/places/${id}`).then((response) => response?.data);
};

export const remove = async (id?: PlaceIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/places/${id}`).then((response) => response?.data);
};

export const exportPlaces = async () => {
    return await api
        .get<Blob>(`/places`, {
            responseType: 'blob',
            headers: {
                accept: 'text/csv',
            },
        })
        .then((response) => {
            downloadResponseAsBlob(response);
            return undefined;
        });
};
