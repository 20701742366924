import { VFC } from 'react';
import { Button, Form, FormProps, Input, InputNumber } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Seo from '../../../components/Seo';
import ListTitle from '../../../components/ListTitle';
import { getRoute, RoutePathName } from '../../../routes';
import { ValueListItem, ValueListSlug } from '../../../queries/api/types';
import useQueryParams from '../../../hooks/queryParams';
import {
    useValueListItemCreate,
    useValueListItemDetails,
    useValueListItemUpdate,
} from '../../../queries/valueListItems';
import { errorMessage, successMessage } from '../../../helpers/message';
import genericMessages from '../../../i18n/genericMessages';
import SectionCard, { sectionCardFormItemLayout } from '../../../components/SectionCard';
import { getRequiredRule } from '../../../i18n';
import formMessages from '../../../i18n/formMessages';
import { Check } from '../../../components/icons';
import unitMessages from '../../../i18n/unitMessages';

const OperatorsProfileForm: VFC = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [form] = Form.useForm();
    const { valueListItemId } = useParams<{ valueListItemId?: ValueListItem['id'] }>();
    const [queryParams] = useQueryParams('operatorsProfileForm');
    const valueListSlug = queryParams.get('valueListSlug') ?? undefined;
    const isEditing = !!valueListItemId;
    const { data: valueListItemDetails, isLoading: isLoadingDetails } = useValueListItemDetails(
        { id: valueListItemId, valueListSlug },
        {
            enabled: isEditing,
            refetchOnWindowFocus: false,
            staleTime: 0,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                });
            },
        }
    );
    const { mutate: createValueListItem, isLoading: isCreating } = useValueListItemCreate();
    const { mutate: updateValueListItem, isLoading: isUpdating } = useValueListItemUpdate();
    const pageTitle = isEditing
        ? formatMessage({
              id: 'operators_profile_form.title.edit',
              defaultMessage: 'Éditer le profil',
              description: 'Operators add profile page title',
          })
        : formatMessage({
              id: 'operators_profile_form.title.add',
              defaultMessage: 'Ajouter un profil',
              description: 'Operators add profile page title',
          });
    const onSubmit: FormProps['onFinish'] = (values) => {
        if (isEditing) {
            updateValueListItem(
                {
                    id: valueListItemId,
                    valueListSlug,
                    title: 'dummy',
                    meta: {},
                    ...values,
                },
                {
                    onSuccess: () => {
                        successMessage({
                            content: formatMessage({
                                id: 'operators_profile_form.update_success_message',
                                defaultMessage: 'Profil modifié avec succès',
                            }),
                        });
                        history.push({
                            pathname: getRoute(RoutePathName.operatorsValueLists),
                            state: { tab: ValueListSlug.operatorProfiles },
                        });
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status,
                            }),
                        });
                    },
                }
            );
        } else {
            createValueListItem(
                {
                    valueListSlug,
                    title: 'dummy',
                    meta: {},
                    ...values,
                },
                {
                    onSuccess: () => {
                        successMessage({
                            content: formatMessage({
                                id: 'operators_profile_form.create_success_message',
                                defaultMessage: 'Profil ajouté avec succès',
                            }),
                        });
                        history.push({
                            pathname: getRoute(RoutePathName.operatorsValueLists),
                            state: { tab: ValueListSlug.operatorProfiles },
                        });
                    },
                    onError: (error) => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultErrorWithStatus, {
                                status: error.response?.status,
                            }),
                        });
                    },
                }
            );
        }
    };

    return (
        <>
            <Seo title={pageTitle} />
            <div className="flex justify-between items-center mb-6">
                <ListTitle
                    className="mb-0 uppercase"
                    backRoute={{
                        pathname: getRoute(RoutePathName.operatorsValueLists),
                        state: { tab: ValueListSlug.operatorProfiles },
                    }}
                >
                    {pageTitle}
                </ListTitle>
            </div>
            <Form
                form={form}
                onFinish={onSubmit}
                layout="horizontal"
                initialValues={isEditing ? { ...valueListItemDetails } : undefined}
                requiredMark
            >
                <Form.Item name={['fields', 'operatorProfileName']} rules={[getRequiredRule(formatMessage)]} required>
                    <Input size="large" placeholder={formatMessage(formMessages.valueListInputPlaceholder)} />
                </Form.Item>
                <div className="space-y-4 mb-8">
                    <SectionCard
                        title={formatMessage({
                            id: 'operators_profile_form.form_section.work_duration',
                            defaultMessage: 'Temps de travail',
                        })}
                        isLoading={isLoadingDetails}
                    >
                        <Form.Item
                            name={['fields', 'operatorProfileWorkDuration']}
                            label={formatMessage({
                                id: 'operators_profile_form.form_item.work_duration',
                                defaultMessage: 'Temps de travail / semestre',
                                description: 'form label',
                            })}
                            className="gap-6"
                            rules={[getRequiredRule(formatMessage)]}
                            {...sectionCardFormItemLayout}
                            required
                        >
                            <InputNumber
                                placeholder={formatMessage(formMessages.valueListInputPlaceholder)}
                                className="w-full"
                                addonAfter={formatMessage(unitMessages.hourShort)}
                                min={0}
                            />
                        </Form.Item>
                    </SectionCard>
                    <SectionCard title={formatMessage(genericMessages.workDurationLimits)} isLoading={isLoadingDetails}>
                        <Form.Item
                            name={['fields', 'operatorProfileDays']}
                            label={formatMessage({
                                id: 'operators_profile_form.form_item.days',
                                defaultMessage: 'Nombre de jours travaillé (par semaine)',
                                description: 'form label',
                            })}
                            className="gap-6"
                            rules={[getRequiredRule(formatMessage)]}
                            {...sectionCardFormItemLayout}
                            required
                        >
                            <InputNumber
                                placeholder={formatMessage(formMessages.valueListInputPlaceholder)}
                                className="w-full"
                                addonAfter={formatMessage(unitMessages.dayShort)}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item
                            name={['fields', 'operatorProfileMinimumHoursPerDay']}
                            label={formatMessage({
                                id: 'operators_profile_form.form_item.min_hours_per_day',
                                defaultMessage: 'Temps de travail minimum (par jour)',
                                description: 'form label',
                            })}
                            className="gap-6"
                            rules={[getRequiredRule(formatMessage)]}
                            {...sectionCardFormItemLayout}
                            required
                        >
                            <InputNumber
                                placeholder={formatMessage(formMessages.valueListInputPlaceholder)}
                                className="w-full"
                                addonAfter={formatMessage(unitMessages.hourShort)}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item
                            name={['fields', 'operatorProfileMaximumHoursPerDay']}
                            label={formatMessage({
                                id: 'operators_profile_form.form_item.max_hours_per_day',
                                defaultMessage: 'Temps de travail maximum (par jour)',
                                description: 'form label',
                            })}
                            className="gap-6"
                            rules={[getRequiredRule(formatMessage)]}
                            {...sectionCardFormItemLayout}
                            required
                        >
                            <InputNumber
                                placeholder={formatMessage(formMessages.valueListInputPlaceholder)}
                                className="w-full"
                                addonAfter={formatMessage(unitMessages.hourShort)}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item
                            name={['fields', 'operatorProfileMinimumHoursPerWeek']}
                            label={formatMessage({
                                id: 'operators_profile_form.form_item.min_hours_per_week',
                                defaultMessage: 'Temps de travail minimum (par semaine)',
                                description: 'form label',
                            })}
                            className="gap-6"
                            rules={[getRequiredRule(formatMessage)]}
                            {...sectionCardFormItemLayout}
                            required
                        >
                            <InputNumber
                                placeholder={formatMessage(formMessages.valueListInputPlaceholder)}
                                className="w-full"
                                addonAfter={formatMessage(unitMessages.hourShort)}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item
                            name={['fields', 'operatorProfileMaximumHoursPerWeek']}
                            label={formatMessage({
                                id: 'operators_profile_form.form_item.max_hours_per_week',
                                defaultMessage: 'Temps de travail maximum (par semaine)',
                                description: 'form label',
                            })}
                            className="gap-6"
                            rules={[getRequiredRule(formatMessage)]}
                            {...sectionCardFormItemLayout}
                            required
                        >
                            <InputNumber
                                placeholder={formatMessage(formMessages.valueListInputPlaceholder)}
                                className="w-full"
                                addonAfter={formatMessage(unitMessages.hourShort)}
                                min={0}
                            />
                        </Form.Item>
                    </SectionCard>
                    <SectionCard
                        title={formatMessage({
                            id: 'operators_profile_form.form_section.sick_days',
                            defaultMessage: 'Repos',
                        })}
                        isLoading={isLoadingDetails}
                    >
                        <Form.Item
                            name={['fields', 'operatorProfileSickDays']}
                            label={formatMessage({
                                id: 'operators_profile_form.form_item.sick_days',
                                defaultMessage: "Nombre d'heures de repos par période de 7 jours glissants",
                                description: 'form label',
                            })}
                            className="gap-6"
                            rules={[getRequiredRule(formatMessage)]}
                            {...sectionCardFormItemLayout}
                            required
                        >
                            <InputNumber
                                placeholder={formatMessage(formMessages.valueListInputPlaceholder)}
                                className="w-full"
                                addonAfter={formatMessage(unitMessages.hourShort)}
                                min={0}
                            />
                        </Form.Item>
                    </SectionCard>
                </div>
                <div className="flex justify-center">
                    <Button
                        type="primary"
                        size="large"
                        icon={<Check />}
                        disabled={isLoadingDetails}
                        loading={isCreating || isUpdating}
                        htmlType="submit"
                    >
                        <FormattedMessage {...formMessages.saveData} tagName="span" />
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default OperatorsProfileForm;
