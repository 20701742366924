import { useCallback, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Badge, Button, Spin, Tabs } from 'antd';

import { getRoute, RoutePathName } from '../../../routes';
import { IssueStatus, Vehicle } from '../../../queries/api/types';
import { useVehicleDetails, useVehicleExport, useVehicleListIssues } from '../../../queries/vehicles';
import genericMessages from '../../../i18n/genericMessages';
import vehicleMessages from '../../../i18n/vehicleMessages';
import { errorMessage } from '../../../helpers/message';
import ListTitle from '../../../components/ListTitle';
import ApiResult from '../../../components/ApiResult';
import { Download } from '../../../components/icons';
import ManagementVehiclesDetailsInfos from './ManagementVehiclesDetailsInfos';
import ManagementVehiclesDetailsHistory from './ManagementVehiclesDetailsHistory';
import ManagementVehiclesDetailsIssues from './ManagementVehiclesDetailsIssues';
import { ManagementVehiclesDetailsTabs } from '.';
import useQueryParams from '../../../hooks/queryParams';

const ManagementVehiclesDetails: VFC = () => {
    const [queryParams] = useQueryParams('vehicle-details');
    const { vehicleId, tab } = useParams<{ vehicleId: Vehicle['id']; tab: ManagementVehiclesDetailsTabs }>();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { data: vehicleDetails, isError, error } = useVehicleDetails(vehicleId, { enabled: !!vehicleId });
    const { data: issues } = useVehicleListIssues(
        { id: vehicleId, status: [IssueStatus.opened, IssueStatus.repairing] },
        { enabled: !!vehicleId }
    );
    const pageTitle = vehicleDetails?.reference || <Spin />;
    const onChangeTab = useCallback(
        (tab) => {
            history.push(
                getRoute(RoutePathName.managementVehiclesDetails, {
                    vehicleId,
                    tab: tab as ManagementVehiclesDetailsTabs,
                })
            );
        },
        [history, vehicleId]
    );
    const fromDate = queryParams.get('fromDate') ?? undefined;
    const toDate = queryParams.get('toDate') ?? undefined;

    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const { isLoading: isExporting } = useVehicleExport(
        { vehicleId, exportType: tab, fromDate, toDate },
        {
            enabled: isExportEnabled,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, { status: error?.response?.status }),
                });
            },
            onSettled: () => {
                setIsExportEnabled(false);
            },
        }
    );

    return isError ? (
        <ApiResult status={error.response?.status} />
    ) : (
        <>
            <div className="flex justify-between items-center space-x-2">
                <ListTitle className="mb-0 uppercase" backRoute={getRoute(RoutePathName.managementVehicles)}>
                    {pageTitle}
                </ListTitle>

                <Button
                    type="primary"
                    icon={<Download className="text-base" />}
                    onClick={() => setIsExportEnabled(true)}
                    loading={isExporting}
                >
                    <FormattedMessage {...genericMessages.export} tagName="span" />
                </Button>
            </div>
            <Tabs onChange={onChangeTab} defaultActiveKey={tab}>
                {Object.values(ManagementVehiclesDetailsTabs).map((tab) => {
                    switch (tab) {
                        case ManagementVehiclesDetailsTabs.history:
                            return (
                                <Tabs.TabPane tab={formatMessage(genericMessages.historyAndAnalytics)} key={tab}>
                                    <ManagementVehiclesDetailsHistory />
                                </Tabs.TabPane>
                            );

                        case ManagementVehiclesDetailsTabs.issues:
                            return (
                                <Tabs.TabPane
                                    tab={
                                        <>
                                            {formatMessage(vehicleMessages.managementIssues)}{' '}
                                            <Badge
                                                count={issues?.totalCount ?? 0}
                                                overflowCount={999999}
                                                style={{ backgroundColor: '#e5322c' }}
                                            />
                                        </>
                                    }
                                    key={tab}
                                >
                                    <ManagementVehiclesDetailsIssues />
                                </Tabs.TabPane>
                            );

                        case ManagementVehiclesDetailsTabs.information:
                            return (
                                <Tabs.TabPane tab={formatMessage(genericMessages.informations)} key={tab}>
                                    <ManagementVehiclesDetailsInfos />
                                </Tabs.TabPane>
                            );

                        default:
                            return null;
                    }
                })}
            </Tabs>
        </>
    );
};

export default ManagementVehiclesDetails;
