import { defineMessages } from 'react-intl';

const vehicleMessages = defineMessages({
    referencePlaceholder: {
        id: 'vehicle.form.reference.placeholder',
        defaultMessage: 'Saisir un ID / une référence',
        description: 'placeholder',
    },
    ownership: {
        id: 'vehicle.ownership',
        defaultMessage: 'Propriété',
        description: 'form section title, list header',
    },
    plateNumber: {
        id: 'vehicle.plate_number',
        defaultMessage: 'Immatriculation',
        description: 'form section title, list header',
    },
    samsara: {
        id: 'vehicle.samsara',
        defaultMessage: 'Données provenant de SAMSARA',
        description: 'information tooltip',
    },
    mobileApp: {
        id: 'vehicle.mobile_app',
        defaultMessage: "Données provenant de l'application chauffeur",
        description: 'information tooltip',
    },
    managementIssues: {
        id: 'management.vehicles.issues.title',
        defaultMessage: 'Suivi des incidents',
        description: 'Tab',
    },
    mileage: {
        id: 'vehicle.mileage',
        defaultMessage: 'Kilométrage',
    },
});

export default vehicleMessages;
