import { Badge, Form, PopoverProps, Space, TreeSelect, Typography } from 'antd';
import { FC, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import taskGroupMessages from '../../../i18n/taskGroupMessages';
import { TaskGroupListAvailableVehiclesResponse } from '../../../queries/api/taskGroups';
import { Vehicle } from '../../../queries/api/types';

const formatVehiclesIntoTreeData = (vehicles?: TaskGroupListAvailableVehiclesResponse) => {
    // base acc item to get disabled items if no vehicle found
    const initialTreeData: TreeDataItem[] = [
        {
            title: (
                <>
                    {'ID Logistics'} <Badge count={0} size="small" showZero />
                </>
            ),
            value: 'inHouse',
            disabled: true,
            selectable: false,
            children: [],
        },
        {
            title: (
                <>
                    {'Partenaires transports'} <Badge count={0} size="small" showZero />
                </>
            ),
            value: 'transporter',
            disabled: true,
            selectable: false,
            children: [],
        },
        {
            title: (
                <>
                    {'Relais'} <Badge count={0} size="small" showZero />
                </>
            ),
            value: 'relay',
            disabled: true,
            selectable: false,
            children: [],
        },
    ];
    const treeData = vehicles?.items.reduce((acc, vehicle) => {
        const isAttachedToTransporter = !!vehicle.transporter;
        if (isAttachedToTransporter) {
            const isRelay = vehicle.ownershipType.fields.vehicleOwnershipTypeReference === 'relay';
            const treeDataOwnershipTypeIndex = isRelay ? 2 : 1; // define global treeData index according to vehicle owernshipType to select corresponding item (same logic for relay and transporter)
            acc[treeDataOwnershipTypeIndex].disabled = false;
            const foundAttachedToIndex = acc[treeDataOwnershipTypeIndex].children!.findIndex(
                (att) => att.value === vehicle.transporter!.id
            );
            if (foundAttachedToIndex > -1) {
                const currentVehicleListByTransporter = acc[treeDataOwnershipTypeIndex].children!;
                currentVehicleListByTransporter[foundAttachedToIndex].children = [
                    ...(currentVehicleListByTransporter[foundAttachedToIndex].children || []),
                    {
                        title: vehicle.reference,
                        value: vehicle.id,
                    },
                ];
                currentVehicleListByTransporter[foundAttachedToIndex].title = (
                    <>
                        {vehicle.transporter?.name}{' '}
                        <Badge
                            count={currentVehicleListByTransporter[foundAttachedToIndex].children?.length}
                            size="small"
                            showZero
                        />
                    </>
                );
            } else {
                acc[treeDataOwnershipTypeIndex].children = [
                    ...acc[treeDataOwnershipTypeIndex].children!,
                    {
                        title: (
                            <>
                                {vehicle.transporter?.name} <Badge count={1} size="small" showZero />
                            </>
                        ),
                        value: vehicle.transporter?.id,
                        selectable: false,
                        children: [
                            {
                                title: vehicle.reference,
                                value: vehicle.id,
                            },
                        ],
                    },
                ];
            }
            const total = acc[treeDataOwnershipTypeIndex].children?.reduce(
                (acc, vehiclesByTransporterName) => acc + (vehiclesByTransporterName.children?.length ?? 0),
                0
            );
            acc[treeDataOwnershipTypeIndex].title = (
                <>
                    {isRelay ? 'Relais' : 'Partenaires transports'} <Badge count={total} size="small" showZero />
                </>
            );
        } else {
            acc[0].disabled = false;
            acc[0].children = [...acc[0].children!, { title: vehicle.reference, value: vehicle.id }];
            acc[0].title = (
                <>
                    {'ID Logistics'} <Badge count={acc[0].children.length} size="small" showZero />
                </>
            );
        }
        return acc;
    }, initialTreeData);
    return treeData;
};

interface TreeDataItem {
    title?: ReactNode;
    disabled?: boolean;
    value?: string | number | null;
    children?: TreeDataItem[];
    selectable?: boolean;
}

interface TaskGroupVehicleSelectorProps extends PopoverProps {
    vehicleId: Vehicle['id'] | undefined;
    vehicles?: TaskGroupListAvailableVehiclesResponse;
}

const TaskGroupVehicleSelector: FC<TaskGroupVehicleSelectorProps> = ({ vehicleId, vehicles }) => {
    const { formatMessage } = useIntl();
    const treeData = useMemo(() => formatVehiclesIntoTreeData(vehicles), [vehicles]);
    const form = Form.useFormInstance();

    return (
        <Space direction="vertical" size={4} className="w-full">
            <Typography.Text>{formatMessage(taskGroupMessages.vehicleSelectorLabel)}</Typography.Text>
            <TreeSelect<Vehicle['id'], TreeDataItem>
                treeData={treeData}
                placeholder={formatMessage(taskGroupMessages.vehicleSelectorPlaceholder)}
                className="w-full mb-4"
                value={vehicleId}
                onChange={(id) => {
                    form.setFieldsValue({ vehicleId: id });
                }}
                allowClear
            />
        </Space>
    );
};

export default TaskGroupVehicleSelector;
