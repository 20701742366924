import { VFC } from 'react';
import { Card, Divider, Popover } from 'antd';
import { FormattedDate, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { SlotLabelContentArg } from '@fullcalendar/react';
import { useParams } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';

import genericMessages from '../../../i18n/genericMessages';
import CustomCalendarTimeline, { FullCalendarTimelineViews } from '../../../components/CustomCalendarTimeline';
import { OperatorAvailability, VehicleAvailability } from '../../../queries/api/types';
import { PlanningsTabs } from './ManagementPlannings';
import planningsMessages from '../../../i18n/planningsMessages';

export interface PlanningsAvailabilitiesProps {
    activeDate: Dayjs;
    activeView: FullCalendarTimelineViews;
    operatorAvailabilities?: OperatorAvailability[];
    vehicleAvailabilities?: VehicleAvailability[];
}

const PlanningsAvailabilities: VFC<PlanningsAvailabilitiesProps> = ({
    activeDate,
    activeView,
    operatorAvailabilities,
    vehicleAvailabilities,
}) => {
    const { formatMessage } = useIntl();
    const { tab } = useParams<{ tab: PlanningsTabs }>();

    const renderAvailability = (date: Date) => {
        const formatParamDate = dayjs(date).format('YYYY-MM-DD');

        if (operatorAvailabilities) {
            const findDate = operatorAvailabilities?.find((arg) => {
                const formatCurrDate = dayjs(arg.date).format('YYYY-MM-DD');
                return formatCurrDate === formatParamDate;
            });

            return <span className="text-base font-medium text-black">{findDate?.availableOperators || '-'}</span>;
        }

        if (vehicleAvailabilities) {
            const findDate = vehicleAvailabilities?.find((arg) => {
                const formatCurrDate = dayjs(arg.date).format('YYYY-MM-DD');
                return formatCurrDate === formatParamDate;
            });

            return <span className="text-base font-medium text-black">{findDate?.availableVehicles || '-'}</span>;
        }

        return '-';
    };

    const resourceAreaColumns = [
        {
            headerContent: (
                <span>
                    {formatMessage(genericMessages.availabilities)}{' '}
                    <Popover content={formatMessage(planningsMessages.availabilitiesTooltip, { ressource: tab })}>
                        <QuestionCircleOutlined />
                    </Popover>
                </span>
            ),
        },
    ];

    const renderSlotLabelContent = ({ date }: SlotLabelContentArg) => (
        <>
            <span className="block mt-2">
                <FormattedDate value={date} weekday="short" day="numeric" month="2-digit" />
            </span>
            <Divider style={{ marginTop: 8, marginBottom: 4 }} />
            {renderAvailability(date)}
        </>
    );
    return (
        <Card
            className="border-t-0 border-b-0 custom-fullcalendar__availabilities"
            bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        >
            <CustomCalendarTimeline
                activeDate={activeDate}
                initialView={activeView}
                resizeKey={tab}
                resourceAreaColumns={resourceAreaColumns}
                slotLabelContent={renderSlotLabelContent}
                slotDuration="24:00"
                slotLabelFormat={{
                    weekday: 'short',
                    day: 'numeric',
                    month: '2-digit',
                }}
                slotMinWidth={115}
            />
        </Card>
    );
};

export default PlanningsAvailabilities;
