import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { useAuth } from '../context/AuthContext';
import {
    create as customerForecastCreate,
    list as customerForecastList,
    details as customerForecastDetails,
    update as customerForecastUpdate,
    remove as customerForecastRemove,
    CustomerForecastCreatePayload,
    CustomerForecastListPayload,
    CustomerForecastListResponse,
    CustomerForecastUpdatePayload,
    CustomerForecastRemovePayload,
    CustomerForecastDetailsPayload,
} from './api/customerForecasts';
import {
    create,
    details,
    list,
    CustomerCreatePayload,
    CustomerIdPayload,
    CustomerListPayload,
    CustomerListResponse,
    CustomerUpdatePayload,
    remove,
    update,
    exportCustomers,
} from './api/customers';
import { Customer, CustomerForecast, RoleSlug } from './api/types';

export const customerKeys = {
    all: ['customers'],
    lists: () => [...customerKeys.all, 'list'],
    list: (params?: CustomerListPayload) => [...customerKeys.lists(), params],
    details: () => [...customerKeys.all, 'details'],
    detail: (id?: CustomerIdPayload) => [...customerKeys.details(), id],
    export: () => [...customerKeys.all, 'export'],
};

export const useCustomersList = <TData = CustomerListResponse>(
    params: CustomerListPayload | undefined,
    options?: UseQueryOptions<CustomerListResponse, AxiosError, TData>
) => {
    const { user } = useAuth();
    return useQuery<CustomerListResponse, AxiosError, TData>(
        customerKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, enabled: user?.role?.slug !== RoleSlug.vehicleTracker, ...options }
    );
};

export const useCustomerDetails = <TData = Customer>(
    id: CustomerIdPayload | undefined,
    options?: UseQueryOptions<Customer, AxiosError, TData>
) => {
    return useQuery<Customer, AxiosError, TData>(customerKeys.detail(id), async () => await details(id), options);
};

export const useCustomerCreate = (options?: UseMutationOptions<Customer, AxiosError, CustomerCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Customer, AxiosError, CustomerCreatePayload>(async (params) => await create(params), {
        ...options,
        onSettled: (...args) => {
            options?.onSettled?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(customerKeys.lists());
        },
    });
};

export const useCustomerUpdate = (options?: UseMutationOptions<Customer, AxiosError, CustomerUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Customer, AxiosError, CustomerUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(customerKeys.detail(variables.id));
        },
    });
};

export const useCustomerRemove = (options?: UseMutationOptions<undefined, AxiosError, CustomerIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, CustomerIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(customerKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(customerKeys.lists());
        },
    });
};

export const customerForecastsKeys = {
    all: ['customerForecasts'],
    lists: () => [...customerForecastsKeys.all, 'list'],
    list: (params?: CustomerForecastListPayload) => [...customerForecastsKeys.lists(), params],
    details: () => [...customerForecastsKeys.all, 'details'],
    detail: (params?: CustomerForecastDetailsPayload) => [...customerForecastsKeys.details(), params],
};

export const useCustomerForecastsList = <TData = CustomerForecastListResponse>(
    params?: CustomerForecastListPayload,
    options?: UseQueryOptions<CustomerForecastListResponse, AxiosError, TData>
) => {
    return useQuery<CustomerForecastListResponse, AxiosError, TData>(
        customerForecastsKeys.list(params),
        async () => await customerForecastList(params),
        { keepPreviousData: true, ...options }
    );
};

export const useCustomerForecastsDetails = <TData = CustomerForecastListResponse>(
    payload: CustomerForecastDetailsPayload,
    options?: UseQueryOptions<CustomerForecast, AxiosError, TData>
) => {
    return useQuery<CustomerForecast, AxiosError, TData>(
        customerForecastsKeys.detail(payload),
        async () => await customerForecastDetails(payload),
        options
    );
};

export const useCustomerForecastsCreate = (
    options?: UseMutationOptions<CustomerForecast, AxiosError, CustomerForecastCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<CustomerForecast, AxiosError, CustomerForecastCreatePayload>(
        async (params) => await customerForecastCreate(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(customerForecastsKeys.lists());

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(customerKeys.lists());

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(customerForecastsKeys.details());
            },
        }
    );
};

export const useCustomerForecastsUpdate = (
    options?: UseMutationOptions<CustomerForecast, AxiosError, CustomerForecastUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<CustomerForecast, AxiosError, CustomerForecastUpdatePayload>(
        async (params) => await customerForecastUpdate(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(customerForecastsKeys.details());

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(customerKeys.lists());

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(customerForecastsKeys.lists());
            },
        }
    );
};

export const useCustomerForecastsRemove = (
    options?: UseMutationOptions<undefined, AxiosError, CustomerForecastRemovePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, CustomerForecastRemovePayload>(
        async (params) => await customerForecastRemove(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query since we deleted the item
                queryClient.invalidateQueries(customerForecastsKeys.details());

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(customerKeys.lists());

                // invalidate list queries to refetch for refreshing the list views
                queryClient.invalidateQueries(customerForecastsKeys.lists());
            },
        }
    );
};

export const useExportCustomers = <TData = undefined>(options?: UseQueryOptions<undefined, AxiosError, TData>) => {
    return useQuery<undefined, AxiosError, TData>(customerKeys.export(), async () => await exportCustomers(), options);
};
