import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    OperatorAbsenceCreatePayload,
    OperatorAbsenceIdPayload,
    OperatorAbsenceListPayload,
    OperatorAbsenceListResponse,
    OperatorAbsenceUpdatePayload,
    remove,
    update,
} from './api/operatorAbsences';
import { OperatorAbsence } from './api/types';
import { operatorsKeys } from './operators';

export const operatorAbsencesKeys = {
    all: ['operatorAbsences'],
    lists: () => [...operatorAbsencesKeys.all, 'list'],
    list: (params?: OperatorAbsenceListPayload) => [...operatorAbsencesKeys.lists(), params],
    details: () => [...operatorAbsencesKeys.all, 'details'],
    detail: (id?: OperatorAbsenceIdPayload) => [...operatorAbsencesKeys.details(), id],
};

export const useOperatorAbsenceList = <TData = OperatorAbsenceListResponse>(
    params?: OperatorAbsenceListPayload,
    options?: UseQueryOptions<OperatorAbsenceListResponse, AxiosError, TData>
) => {
    return useQuery<OperatorAbsenceListResponse, AxiosError, TData>(
        operatorAbsencesKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useOperatorAbsenceDetails = <TData = OperatorAbsence>(
    id?: OperatorAbsenceIdPayload,
    options?: UseQueryOptions<OperatorAbsence, AxiosError, TData>
) => {
    return useQuery<OperatorAbsence, AxiosError, TData>(
        operatorAbsencesKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useOperatorAbsenceCreate = (
    options?: UseMutationOptions<OperatorAbsence[], AxiosError, OperatorAbsenceCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<OperatorAbsence[], AxiosError, OperatorAbsenceCreatePayload>(
        async (params) => await create(params),
        {
            ...options,
            onSuccess: (...args) => {
                options?.onSuccess?.(...args);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(operatorAbsencesKeys.lists());

                queryClient.invalidateQueries(operatorsKeys.listAbsencesAll());
            },
        }
    );
};

export const useOperatorAbsenceUpdate = (
    options?: UseMutationOptions<OperatorAbsence, AxiosError, OperatorAbsenceUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<OperatorAbsence, AxiosError, OperatorAbsenceUpdatePayload>(
        async (params) => await update(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(operatorAbsencesKeys.detail(variables.id));
            },
        }
    );
};

export const useOperatorAbsenceRemove = (
    options?: UseMutationOptions<undefined, AxiosError, OperatorAbsenceIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, OperatorAbsenceIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(operatorAbsencesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(operatorAbsencesKeys.lists());
            queryClient.invalidateQueries(operatorsKeys.listAbsencesAll());
        },
    });
};
